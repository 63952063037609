var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CModal",
    {
      staticClass: "new-image-modal",
      attrs: {
        title: "Add New Image",
        color: "white",
        size: "xl",
        centered: "",
        show: _vm.$store.state.newImageAsideShow,
        closeOnBackdrop: false,
      },
      on: {
        "update:show": function ($event) {
          return _vm.$set(_vm.$store.state, "newImageAsideShow", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _vm.form.type === "Hero"
                ? _c("CInputCheckbox", {
                    attrs: {
                      custom: "",
                      label: "Add also as category image",
                      checked: _vm.addAlsoAsCategoryImage,
                    },
                    on: {
                      "update:checked": function ($event) {
                        _vm.addAlsoAsCategoryImage = $event
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "CButton",
                {
                  attrs: {
                    color: "primary",
                    shape: "pill",
                    disabled: _vm.submitted,
                  },
                  on: { click: _vm.submit },
                },
                [
                  _c("CIcon", { attrs: { name: "cil-send" } }),
                  _vm._v(" Submit"),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("CForm", [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "form-group col-sm-4" },
            [
              _c("label", { staticClass: "d-block" }, [_vm._v("Image Type:")]),
              _c("v-select", {
                attrs: {
                  placeholder: "Select..",
                  options: _vm.typeList,
                  searchable: false,
                },
                on: { input: (t) => _vm.$emit("typeChanged", t) },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (type) {
                      return [_c("span", [_vm._v(_vm._s(type.label))])]
                    },
                  },
                ]),
                model: {
                  value: _vm.$v.form.type.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.form.type, "$model", $$v)
                  },
                  expression: "$v.form.type.$model",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group col-sm-8" },
            [
              _c("label", { staticClass: "d-block" }, [_vm._v("Tags:")]),
              _c("v-select", {
                attrs: {
                  placeholder: "Select..",
                  options: _vm.tagList,
                  reduce: (tag) => (typeof tag === "object" ? tag.name : tag),
                  label: "name",
                  multiple: "",
                  taggable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function (tag) {
                      return [_c("span", [_vm._v(_vm._s(tag.name ?? tag))])]
                    },
                  },
                ]),
                model: {
                  value: _vm.$v.form.tags.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.form.tags, "$model", $$v)
                  },
                  expression: "$v.form.tags.$model",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "form-group col-sm-4" },
              [
                _c("label", { staticClass: "d-block" }, [_vm._v("Image:")]),
                _c("input", {
                  ref: "inputFile",
                  attrs: { type: "file" },
                  on: { change: _vm.onFileChanged },
                }),
                _c("CInput", {
                  staticClass: "mb-0",
                  attrs: { value: _vm.$v.form.image.$model, type: "hidden" },
                  on: {
                    "update:value": function ($event) {
                      return _vm.$set(_vm.$v.form.image, "$model", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _c("CInput", {
              staticClass: "col-sm-8",
              attrs: {
                label: "File Name:",
                value: _vm.$v.form.name.$model,
                isValid: _vm.checkIfValid("name"),
                disabled: !_vm.form.image,
              },
              on: {
                "update:value": function ($event) {
                  return _vm.$set(_vm.$v.form.name, "$model", $event)
                },
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm" },
            [
              _vm.size.width && _vm.size.height
                ? _c("div", { staticClass: "size-info" }, [
                    _c("div", [
                      _vm._v("Width: " + _vm._s(_vm.size.width) + "px"),
                    ]),
                    _c("div", [
                      _vm._v("Height: " + _vm._s(_vm.size.height) + "px"),
                    ]),
                  ])
                : _vm._e(),
              _c("cropper", {
                ref: "cropper",
                staticClass: "cropper",
                attrs: {
                  src: _vm.previewImage,
                  "stencil-props": {
                    handlers: {},
                    movable: false,
                    resizable: false,
                    aspectRatio: _vm.aspectRatio,
                  },
                  "stencil-size": _vm.stencilSize,
                  "resize-image": {
                    adjustStencil: false,
                  },
                  "image-restriction": "stencil",
                },
                on: { change: _vm.cropperOnChange },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading || _vm.submitted,
            expression: "loading || submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }