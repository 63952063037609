var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CSidebar",
    {
      attrs: {
        aside: "",
        show: _vm.$store.state.assignImageToCategoryAsideShow,
        colorScheme: "light",
        overlaid: "",
        size: "lg",
      },
      on: {
        "update:show": (val) =>
          _vm.$store.commit("set", ["assignImageToCategoryAsideShow", val]),
      },
    },
    [
      _c("CSidebarClose", {
        nativeOn: {
          click: function ($event) {
            return _vm.$store.commit("toggle", "assignImageToCategoryAsideShow")
          },
        },
      }),
      _c(
        "CListGroup",
        { staticClass: "scrollable list-group-accent mt-5" },
        [
          _c(
            "CListGroupItem",
            {
              staticClass:
                "mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase",
            },
            [_vm._v(" Assign to Category ")]
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "list-group-item-divider list-group-item-accent-primary",
            },
            [
              _c("div", { staticClass: "image-container" }, [
                _c("img", { attrs: { src: _vm.image?.image_url } }),
              ]),
              _c(
                "CForm",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.add.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { staticClass: "d-block" }, [
                        _vm._v("Categories"),
                      ]),
                      _c("v-select", {
                        staticClass: "v-select-style",
                        attrs: {
                          placeholder: "Select..",
                          options: _vm.categories,
                          loading: _vm.loading && !_vm.categories.length,
                          multiple: "",
                        },
                        on: { input: _vm.getRestaurantsByCategoryNames },
                        model: {
                          value: _vm.form.categories,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "categories", $$v)
                          },
                          expression: "form.categories",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between" },
                      [
                        _c("label", [_vm._v("Restaurants")]),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-gap-2" },
                          [
                            _c(
                              "CLink",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.form.restaurants =
                                      _vm.restaurants.pluck("id")
                                  },
                                },
                              },
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-library-add" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "CLink",
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.form.restaurants = []
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-library" } })],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "ul",
                      { staticClass: "checklist" },
                      _vm._l(_vm.restaurants, function (item) {
                        return _c("li", [
                          _c("label", { staticClass: "for-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.restaurants,
                                  expression: "form.restaurants",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: item.id,
                                checked: Array.isArray(_vm.form.restaurants)
                                  ? _vm._i(_vm.form.restaurants, item.id) > -1
                                  : _vm.form.restaurants,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.form.restaurants,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.form,
                                          "restaurants",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.form,
                                          "restaurants",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.form, "restaurants", $$c)
                                  }
                                },
                              },
                            }),
                            _vm._v(
                              " #" +
                                _vm._s(item.id) +
                                " - " +
                                _vm._s(item.restaurant_name) +
                                " "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "CListGroupItem",
            {
              staticClass:
                "sticky-bottom list-group-item-accent-primary bg-white",
            },
            [
              _c(
                "CButton",
                {
                  attrs: {
                    color: "primary",
                    disabled: !_vm.form.restaurants?.length,
                  },
                  on: { click: _vm.assign },
                },
                [
                  _c("CIcon", { attrs: { name: "cil-send" } }),
                  _vm._v(" Submit "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("CElementCover", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading || _vm.submitted,
            expression: "loading || submitted",
          },
        ],
        attrs: { opacity: 0.4 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }