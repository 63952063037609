var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("article", [
    _c("div", { staticClass: "article-wrapper" }, [
      _c("figure", [
        _c("img", { attrs: { src: _vm.image.image_url, alt: "" } }),
      ]),
      _c("div", { staticClass: "article-body" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-gap-1 mb-2" },
          _vm._l(_vm.image.tags, function (tag, index) {
            return _c("CBadge", { attrs: { color: "info" } }, [
              _vm._v(_vm._s(tag.name)),
            ])
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex justify-content-between" },
          [
            _c("CLink", { on: { click: _vm.showAssignImage } }, [
              _vm.image.type == "category"
                ? _c("span", [_vm._v("Assign to Category")])
                : _vm.image.type == "hero"
                ? _c("span", [_vm._v("Assign to Restaurant")])
                : _vm._e(),
              _c(
                "svg",
                {
                  staticClass: "icon",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 20 20",
                    fill: "currentColor",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      d: "M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z",
                      "clip-rule": "evenodd",
                    },
                  }),
                ]
              ),
            ]),
            _vm.image.is_deletable
              ? _c(
                  "div",
                  [
                    _vm.pendingDelete
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex align-items-center flex-gap-1",
                          },
                          [
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v("Are you sure?"),
                            ]),
                            _c(
                              "CButton",
                              {
                                attrs: { size: "sm", color: "link" },
                                on: { click: _vm.deleteItem },
                              },
                              [
                                _c("CIcon", {
                                  attrs: { name: "cil-check-alt" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "CButton",
                              {
                                attrs: { size: "sm", color: "link" },
                                on: {
                                  click: function ($event) {
                                    _vm.pendingDelete = false
                                  },
                                },
                              },
                              [_c("CIcon", { attrs: { name: "cil-x" } })],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "CButton",
                          {
                            attrs: {
                              size: "sm",
                              color: "danger",
                              variant: "ghost",
                              shape: "pill",
                            },
                            on: {
                              click: function ($event) {
                                _vm.pendingDelete = true
                              },
                            },
                          },
                          [_c("CIcon", { attrs: { name: "cil-trash" } })],
                          1
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }