import { render, staticRenderFns } from "./NewImage.vue?vue&type=template&id=65532548"
import script from "./NewImage.vue?vue&type=script&lang=js"
export * from "./NewImage.vue?vue&type=script&lang=js"
import style0 from "./NewImage.vue?vue&type=style&index=0&id=65532548&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\djram\\git\\menute\\admin-login-eo-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65532548')) {
      api.createRecord('65532548', component.options)
    } else {
      api.reload('65532548', component.options)
    }
    module.hot.accept("./NewImage.vue?vue&type=template&id=65532548", function () {
      api.rerender('65532548', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/images/NewImage.vue"
export default component.exports