var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { sm: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-image" } }),
                  _c("h5", { staticClass: "d-inline ml-2" }, [
                    _vm._v("Images"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-header-actions" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: { color: "primary" },
                          on: { click: _vm.addNewImage },
                        },
                        [
                          _c("CIcon", {
                            staticClass: "align-bottom",
                            attrs: { name: "cil-plus" },
                          }),
                          _c("strong", [_vm._v("Add New")]),
                          _vm._v(" Image "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Images", {
                attrs: {
                  typeList: _vm.typeList,
                  tagList: _vm.tagList,
                  images: _vm.images,
                },
                on: {
                  "update:images": function ($event) {
                    _vm.images = $event
                  },
                  typeChanged: _vm.getTags,
                },
              }),
              _c("NewImage", {
                attrs: {
                  typeList: _vm.typeList,
                  tagList: _vm.tagList,
                  response: _vm.response,
                },
                on: {
                  "update:response": function ($event) {
                    _vm.response = $event
                  },
                  typeChanged: _vm.getTags,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }